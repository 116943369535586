html, body { 
  text-size-adjust: none;
 -webkit-text-size-adjust: none;
 -moz-text-size-adjust: none;
 -ms-text-size-adjust: none;
}

body {
  background-color: rgb(13, 1, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:not(input) {
    user-select: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

  

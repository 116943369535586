.offline-circle {
  height: 1rem;
  width: 1rem;
  background-color: rgb(192, 49, 49);
  border-radius: 50%;
}

.online-circle {
  height: 1rem;
  width: 1rem;
  background-color: rgb(15, 116, 15);
  border-radius: 50%;
}
  
  
.form {
	&_flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 5vw;
		margin: calc(var(--vh, 1vh) * 5);
		font-family: $main-font;
		height: 100%;
		padding-bottom: 6vh;
		padding-bottom: calc(var(--vh, 1vh) * 6);
	}
	&__input {
		&_err {
			border-color: $font-color-highlight;
		}
		margin: 0 0 4vw 0;
		margin: 0 0 calc(var(--vh, 1vh) * 4) 0;
		outline: none;
		padding: 1vw;
		font-size: 5vw;
		background: $widget-color;
		border: $border;
		border-radius: $border-radius;
		color: $font-color-main;
		&_grow {
			width: 85%;
		}
		&-wrapper {
			display: flex;
			position: relative;
		}
	}
	&__button {
		margin-bottom: 3vw;
	}
	&__scan-button {
		margin: 0 0 4vw 5px;
		margin: 0 0 calc(var(--vh, 1vh) * 4) 5px;
		background: transparent;
		height: 4.5vw;
		height: calc(var(--vh, 1vh) * 4.5);
		width: 4.5vw;
		width: calc(var(--vh, 1vh) * 4.5);
		display: flex;
		border: 0;
	}
	&__absolut {
		position: absolute !important;
		left: 5px;
		right: 5px;
		top: 5.5vw;
		top: calc(var(--vh, 1vh) * 5.5);
		min-height: 150px;
		border: 2px rgb(228, 77, 77) solid;
		overflow: hidden;
		border-radius: 5px;
		background-color: black;
		background-image: url("../../../public/images/spiner.gif");
		background-size: auto 80%;
		background-position: center;
		background-repeat: no-repeat;
		video {
			width: 100% !important;
		}
	}
	&__scan-img {
		height: 100%;
	}
	&__label {
		display: block;
		color: $font-color-main;
		font-size: 5vw;
		margin-bottom: 2vw;
		text-align: center;
	}
	&__link {
		text-decoration: none;
		color: $font-color-main;
	}
	&__error {
		color: $font-color-highlight;
		margin-bottom: 2vw;
		text-align: center;
	}
}
.burger-menu {
  &__toggle {
    display: none;
    &:checked ~ .burger-menu__box {
      display: block;
    }
  }
  &__box {
    display: none;
    position: fixed;
    z-index: 100;
    top: 1vh;
    left: 1vh;
    right: 1vh;
    bottom: 1vh;
    padding: 15vh 0;
    padding: calc(var(--vh, 1vh) * 15) 0;
    list-style: none;
    text-align: center;
    background-color: $widget-color;
    border: $border;
    border-radius: $border-radius;
  }
  &__button {
    width: 100%;
  }
  &__exit {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    &::before {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 4px;
      background-color: $font-color-main;
      transform: rotate(45deg);
    }
    &::after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 4px;
      background-color: $font-color-main;
      transform: rotate(-45deg);
    }
  }
  &__item {
    display: block;
    padding: 12px 24px;
    color: $font-color-main;
    font-family: $main-font;
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    &:hover {
      background-color: $widget-color;
    }
  }
  flex: 0 0 20%;
}
.widget {
  &_main {
    flex: 2 0;
  }
  &_main-1 {
    flex: 3 0;
  }
  &_sup {
    flex: 1 0;
  }
  &_author {
    flex: 1 1;
  }
  &_devices {
    flex: 1 1;
  }
  &_kotel {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_settings {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_boler {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_termos {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__subwidget {
    position: relative;
  }
  &__subwidget_main {
    flex: 3 0;
  }
  &__subwidget_sup {
    flex: 2 0;
  }
  &__hr {
    height: 0;
    border-top: $border;
    margin: 0 2vw;
  }
  &_layout {
    background: $widget-color;
    margin-bottom: 1.5vh;
    margin-bottom: calc(var(--vh, 1vh) * 1.5);
    border: $border;
    border-radius: $border-radius;
  }
  &_position {
    position: relative;
  }
  &__out {
    &_corner {
      color: $font-color-sup;
      border-bottom: $border;
      border-left: $border;
      height: 17vw;
      width: 19vw;
      border-radius: 0 0 0 $border-radius;
      font-size: 16vw;
      position: absolute;
      right: 2vw;
      top: 2vw;
      text-align: center;
      &-big {
        height: 20vw;
        width: 24vw;
        font-size: 19vw;
      }
    }
    &_corner-bottom {
      &-left {
        top: auto;
        left: 1vw;
        bottom: 0;
        border-top: $border;
        border-right: $border;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0 $border-radius 0 0;
      }
      &-right {
        top: auto;
        right: 1vw;
        bottom: 0;
        border-top: $border;
        border-right: 0;
        border-left: $border;
        border-bottom: 0;
        border-radius: $border-radius 0 0 0;
      } 
    }
    &_center {
      color: $font-color-main;
      text-align: right;
      margin: 2vw;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      align-content: flex-start;
    }
    &_compact {
      position: relative;
      color: $font-color-main;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      margin-right: 30vw;
      align-items: baseline;
      align-content: flex-start;
    }
    &_bottom {
      width: 65vw;
      height: 11vh;
      height: calc(var(--vh, 1vh) * 11);
      position: absolute;
      margin: 1vh;
      margin: calc(var(--vh, 1vh) * 1);
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      color: $font-color-main;
      &_long {
        height: 29vh;
        height: calc(var(--vh, 1vh) * 29);
      }
    }
    &_double {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 2.5vw;
      left: 50%;
      top: 19vw;
      width: 50vw;
      margin-left: -25vw;
      color: $font-color-main;
      font-size: 13vw;
      text-align: center;
    }
    &-section {
      width: 50%;
      position: relative;
      flex-basis: 50%;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 20% 2vw;
      &_left {
        border-right: $border;
      }
    }
    &_bottom-center {
      height: 5vh;
      height: calc(var(--vh, 1vh) * 5);
      width: 100%;
      position: absolute;
      padding: 1vh;
      padding: calc(var(--vh, 1vh) * 1);
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      color: $font-color-main;
    }

    &_center-center {
      height: 30vh;
      height: calc(var(--vh, 1vh) * 30);
      width: 100%;
      position: absolute;
      padding: 1vh;
      padding: calc(var(--vh, 1vh) * 1);
      top: 14vh;
      top: calc(var(--vh, 1vh) * 14);
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      color: $font-color-main;
    }
    
    &_top {
      color: $font-color-main;
      text-align: right;
      margin: 4vw;
      text-align: center;
      font-size: 10vw;
    }
    &_column {
      width: 24vw;
      height: 8vh;
      height: calc(var(--vh, 1vh) * 8);
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1vh;
      margin: calc(var(--vh, 1vh) * 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      color: $font-color-main;
      &_left {
        left: 2vw;
        right: auto;
        bottom: 15vh;
        bottom: calc(var(--vh, 1vh) * 15);
      }
    }
    &_row {
      width: 70vw;
      height: 8vh;
      height: calc(var(--vh, 1vh) * 7);
      position: absolute;
      bottom: 0;
      right: 3vw;
      margin: 1vh;
      margin: calc(var(--vh, 1vh) * 1);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: center;
      color: $font-color-main;
    }
    font-family: $LCD;
    font-weight: bold;
    font-style: italic;
  }
  &__span-int {
    &_big {
      font-size: 52vw;
      line-height: 42vw;
    }
    &_compact {
      font-size: 27vw;
      line-height: 25vw;
    }
    font-size: 39vw;
    line-height: 30vw;
  }
  &__span-fraction {
    &_big {
      font-size: 23vw;
    }
    &_compact {
      font-size: 10vw;
    }
    font-size: 15vw;
  }
  &__span-unit {
    font-family: "Myriad Pro", Arial;
    font-size: 7vw;
    font-weight: bold;
    font-style: italic;
    &_compact {
      position: absolute;
      right: 0;
      top: 3vw;
      font-size: 6vw;
    }
  }
  &__text {
    font-family: "Myriad Pro", Arial;
    font-size: 5vw;
    font-weight: bold;
    font-style: italic;
    color: $font-color-main;
    padding: 20px;
    text-align: center;
    &_small {
      font-size: 4vw;
      font-style: normal;
      font-weight: normal;
      color: $font-color-highlight;
      font-family: Arial;
    }
  }
  &__button {
    padding: 10px !important;
    background-color: #ffffff13;
    border: 1px solid #fff;
    border-radius: 5px;
    display: block;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
    color: $font-color-main;
    font-family: Arial;
  }
  &__span-label {
    color: $font-color-highlight;
    font-family: $LCD-text;
    font-weight: normal;
    font-style: normal;
    font-size: 5vh;
    font-size: calc(var(--vh, 1vh) * 5);
    &_small {
      font-size: 3vh;
      height: calc(var(--vh, 1vh) * 3);
      display: block;
      text-align: center;
    }
    &_center {
      display: block;
      text-align: center;
    }
  }
  &__span-info {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 5.5vh;
    font-size: calc(var(--vh, 1vh) * 5.5);
    &_flex {
      display: flex !important;
      justify-content: space-around !important;
      align-items: flex-end;
      &-vertical {
        flex-direction: column;
      }
    }
  }
  &__span-info-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    height: calc(var(--vh, 1vh) * 5);
    font-size: 4vh;
    font-size: calc(var(--vh, 1vh) * 4);

    &_flex {
      display: flex !important;
      width: 100% !important;
      justify-content: flex-end !important;
      &>span>span:last-child {
        min-width: 4.5em;
      }
      &>span>span:last-child {
        min-width: 4.5em;
      }
    }

    &_column {
      display: flex !important;
      flex-direction: column;
      width: 100% !important;
      justify-content: flex-start !important;
      align-items: center;
      height: 100%;
      &>span>span:last-child {
        min-width: 4.5em;
      }
      &>span>span:last-child {
        min-width: 4.5em;
      }
    }

    &_big {
      font-size: 10vw !important;
    }

    &_small {
      font-size: 6.5vw !important;
    }
  }
  &__span-info-img {
    height: 90%;
    margin-right: 2vw;
    &_small {
      height: 3vh;
      height: calc(var(--vh, 1vh) * 3);
    }
    &_mg-lr {
      margin-left: 4vw;
      margin-right: 4vw
    }
  }
  &__icon {
    position: absolute;
    left: 2vw;
    bottom: 2vw;
    border-top: $border;
    border-right: $border;
    height: 15vw;
    width: 15vw;
    border-radius: 0 $border-radius 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &_top {
      bottom: auto !important;
      top: 2vw !important;
      border-top: none !important;
      border-bottom: $border !important;
      border-radius: 0 0 $border-radius 0 !important;
    }
    &_top-center {
      display: flex;
      padding-left: 2vw;
      padding-right: 2vw;
      justify-content: space-between;
      bottom: auto !important;
      top: 2vw !important;
      left: 50%;
      width: 50vw;
      margin-left: -25vw;
      border-top: none !important;
      border-bottom: $border !important;
      border-left: $border !important;
      border-radius: 0 0 $border-radius $border-radius !important;
    }
    &-text {
      font-family: $main-font;
      font-size: 2em;
      color: $font-color-main;
      font-weight: bold;
      font-style: italic;
      align-self: flex-end;
    }
  }
  &__img {
    height: 65%;
    &_big {
      margin-bottom: 2vh;
      height: 7vh;
    }
  }
  &__text_normal {
    font-family: $main-font;
    font-weight: normal;
    font-style: normal;
    font-size: 0.5em;
  }
  &__span-flex {
    display: inline-flex;
    align-items: baseline;
    justify-content: flex-end;
    &>span>span {
      flex-basis: 50%;
      line-height: 1.3em;
    }
    .widget__text_left-border {
      border-left: 3px solid $border-color;
    }
  }
}
@media all and (min-aspect-ratio: 1/2) {
  .widget {
    &_main {
      flex: 2 0;
    }
    &_layout {
      margin-bottom: 0.5vh;
      margin-bottom: calc(var(--vh, 1vh) * 0.5);
    }
    &_position {
      position: relative;
    }
    &__out {
      &_corner {
        height: 12vw;
        width: 14vw;
        font-size: 10vw;
        right: 1vw;
        top: 1vw;
        &-big {
          height: 16vw;
          width: 20vw;
          font-size: 14vw;
        }
      }
      &_corner-bottom {
        &-left {
          top: auto;
          left: 1vw;
          bottom: 0;
        }
        &-right {
          top: auto;
          right: 1vw;
          bottom: 0;
        } 
      }
      &_center {
        margin: 1vw;
      }
      &_bottom {
        width: 70vw;
        height: 11vh;
        height: calc(var(--vh, 1vh) * 11);
        margin: 1vh;
        margin: calc(var(--vh, 1vh) * 1);
        &_long {
          height: 29vh;
          height: calc(var(--vh, 1vh) * 29);
        }
      }
    }
    &__span-int {
      &_big {
        font-size: 43vw;
        line-height: 35vw;
      }
      font-size: 28vw;
      line-height: 22vw;
    }
    &__span-fraction {
      &_big {
        font-size: 19vw;
      }
      font-size: 10vw;
    }
    &__span-unit {
      font-family: "Myriad Pro", Arial;
      font-size: 7vw;
      font-weight: bold;
      font-style: italic;
    }
    &__span-label {
      color: $font-color-highlight;
      font-family: $LCD-text;
      font-weight: normal;
      font-style: normal;
      font-size: 5vh;
      font-size: calc(var(--vh, 1vh) * 5);
      &_small {
        font-size: 3vh;
        height: calc(var(--vh, 1vh) * 3);
      }
    }
    &__span-info {
      &_flex {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
      font-size: 5.5vh;
      font-size: calc(var(--vh, 1vh) * 5.5);
    }
    &__span-info-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 23vw;
      height: 5vh;
      height: calc(var(--vh, 1vh) * 5);

      &_column {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        justify-content: flex-start !important;
        align-items: center;
        height: 100%;
        &>span>span:last-child {
          min-width: 4.5em;
        }
        &>span>span:last-child {
          min-width: 4.5em;
        }
      }
    }
    &__span-info-img {
      height: 90%;
      margin-right: 2vw;
    }
    &__icon {
      position: absolute;
      left: 2vw;
      bottom: 2vw;
      border-top: $border;
      border-right: $border;
      height: 15vw;
      width: 15vw;
      border-radius: 0 $border-radius 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &_top {
        bottom: auto !important;
        top: 2vw !important;
        border-top: none !important;
        border-bottom: $border !important;
        border-radius: 0 0 $border-radius 0 !important;
      }
      &_top-center {
        display: flex;
        padding-left: 2vw;
        padding-right: 2vw;
        justify-content: space-between;
        bottom: auto !important;
        top: 2vw !important;
        left: 50%;
        width: 50vw;
        margin-left: -25vw;
        border-top: none !important;
        border-bottom: $border !important;
        border-left: $border !important;
        border-radius: 0 0 $border-radius $border-radius !important;
      }
    }
    &__img {
      height: 65%;
    }
  }
};
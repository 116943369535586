$bg-color-1: rgba(67,4,21,1);
$bg-color-2: rgba(44,3,13,1);
$bg-color-3: rgba(13,1,1,1);
$pannel-color: #683643;
$widget-color: #1e1e1e;
$border-color: #ffffff;
$border: $border-color solid 1px;
$border-bold: $border-color solid 3px;
$icons-color: #ffffff;
$shadow-color: #FFC400;

$border-radius: 5px;

$font-color-main: #ffffff;
$font-color-sup: #6e6e6e;

$popup-background: #6e6e6e;

$font-color-highlight: #f57e0a;

$button-play-color: #ff8000;

$main-font: "Myread Pro", Arial;
$LCD: "DS-Digital";
$LCD-text: "LCD Nova";


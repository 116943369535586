.content {
  &_position {
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    width: 100%;
  }
  &__page {
    height: 81vh;
    height: calc(var(--vh, 1vh) * 81);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    width: 100%;
    &_long {
      height: 90vh;
      height: calc(var(--vh, 1vh) * 90);
    }
  }
  &__error {
    font-family: $main-font;
    color: $font-color-main;
    text-align: center;
    margin: 10vh 2vw 0 2vw;
    margin: calc(var(--vh, 1vh) * 10) 2vw 0 2vw;
    font-size: 3vh;
    font-size: calc(var(--vh, 1vh) * 3);
  }
}
.menu {
  &__item {
    &_active {
      &::after {
        content: "";
        position: absolute;
        top: 2.5vh;
        border-radius: 50%;
        width: 0;
        height: 0vh;
        background: $shadow-color;
        box-shadow: 0 0 6vw 3vw $shadow-color;
      }
      position: relative;
      text-shadow: 0px -1vw 3vw $shadow-color, 0px 1vw 3vw $shadow-color, 1vw 0px 3vw $shadow-color, -1vw 0px 3vw $shadow-color;
    }
    margin: 1.5vh;
    margin: calc(var(--vh, 1vh) * 1.5);
    display: flex;

    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 10%;
    flex: 0 0 10%;
  }
  &__img {
    &_home {
      background-image: url('./images/home.png');
    }
    &_kotel {
      background-image: url('./images/kotel.png');
    }
    &_boler {
      background-image: url('./images/boler.png');
    }
    &_termostat {
      background-image: url('./images/termostat.png');
    }
    &_settings {
      background-image: url('./images/settings.png');
    }
    flex: 1 1 5vh;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__label {
    font-size: 3vw;
    padding: 0.1vh;
    padding: calc(var(--vh, 1vh) * 0.1);
  }
  list-style-type: none;
  text-decoration: none;
}
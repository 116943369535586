.header {
  &_layout {
    width: 100%;
    height: 6vh;
    height: calc(var(--vh, 1vh) * 6);
    background: $pannel-color;
    border-radius: $border-radius;
    flex: 0 0 6vh;
    flex: 0 0 calc(var(--vh, 1vh) * 6);
    margin-bottom: 1.5vh;
    margin-bottom: calc(var(--vh, 1vh) * 1.5);
  }
  &_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    display: flex;

    flex-grow: 1;
    align-items: center;
  }
  &__title {
    color: $font-color-main;
    font-family: $main-font;
    font-weight: bold;
    font-style: italic;
    flex-grow: 1;
    text-align: center;
    font-size: 2.3vh;
    font-size: calc(var(--vh, 1vh) * 2.3);
  }
  &__label {
    width: 4.5em;
    flex-shrink: 0;
    color: $font-color-main;
    font-family: $main-font;
    font-weight: bold;
    font-style: italic;
    padding-left: 2vh;
    padding-left: calc(var(--vh, 1vh) * 2);
    font-size: 2vh;
    font-size: calc(var(--vh, 1vh) * 2);
  }
  &____label-button {
    height: 100%;
  }
}
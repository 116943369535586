@import './variables';
@import './fonts';
@import './ui';
@import './components/form/form';
@import './components/header/header';
@import './components/burgerMenu/burgerMenu';
@import './components/content/content';
@import './components/widget/widget';
@import './components/menu/menu';
@import './components/footer/footer';
@import './components/button/button';
@import './components/devices/devices.scss';
@import './components/popup/popup';
@import './components/slider/slider';
@import './components/checkboxContainer/checkboxContainer';
@import './components/errorPopup/errorPopup';
  
.wraper {
  &_background {
    position: absolute;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    left: 0;
    right: 0;
    top: 0;
    background: rgb(67,4,21);
    background: linear-gradient(180deg, $bg-color-1 0%, $bg-color-2 61%, $bg-color-3 100%);
    padding: 1vh 4vw 0.5vh 4vw;
    padding: calc(var(--vh, 1vh) * 1) 4vw calc(var(--vh, 1vh) * 0.5) 4vw;
  }
  &_flex {
    display: flex;
    flex-direction: column;
  }
  &__error {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
  }
}
  
  
  
  


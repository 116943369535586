.devices {
  &__item {
    position: relative;
    min-height: 18vw;
    &_offline {
      border-color: $font-color-sup;
      color: $font-color-sup;
    }
    width: 85vw;
    margin: 2vh auto;
    margin: calc(var(--vh, 1vh) * 2) auto;
    border: $border;
    border-radius: $border-radius;
    font-family: $main-font;
    color: $font-color-main;
    padding: 2vw;
    font-size: 5vw;
    &_warning {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      right: 2rem;
      top: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__button {
    margin: 3vh auto;
    margin: calc(var(--vh, 1vh) * 3) auto;
    padding: 2vw;
    background: $font-color-sup;
    position: absolute;
    bottom: 0;
    left: 3vw;
    right: 3vw;
    font-family: $main-font;
    color: $font-color-main;
    font-size: 5vw;
  }
  &__info {
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &.edit {
      opacity: 0;
    }
    &-temp {
      width: 7.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
    &-name {
      max-width: 80%;
    }
  }
  &__control {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.normal {
      display: none;
    }
  }
  &__del {
    &_offline {
      border-color: $font-color-sup;
      background-image: url("./images/del_offline.svg");
    }
    width: 10vw;
    height: 10vw;
    margin: 1.5vw;
    background-image: url("./images/del.svg");
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
  }
  &__edit {
    &_offline {
      border-color: $font-color-sup;
      background-image: url("./images/edit_offline.svg");
    }
    width: 10vw;
    height: 10vw;
    margin: 1.5vw;
    background-image: url("./images/edit.svg");
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
  }
  &__close {
    &_offline {
      background-image: url("./images/close_offline.svg");
    }
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    width: 6vw;
    height: 6vw;
    margin: 1.5vw;
    background-image: url("./images/close.svg");
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
  }
  &__look {
    &_offline {
      border-color: $font-color-sup;
      background-image: url("./images/look_offline.svg");
    }
    width: 8vw;
    height: 8vw;
    margin: 1.5vw;
    background-image: url("./images/look.svg");
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
  }

  list-style-type: none;
  position: relative;
  height: 78vh;
  height: calc(var(--vh, 1vh) * 78);
  overflow-y: scroll;
}
   